<template>
	<div class="flight-item" :class="{'choosed-flight-item': chooseFlight && chooseFlight.flightNo == flightItem.flightNo }">
		<div class="choose-tips-btn" v-if="chooseFlight && chooseFlight.flightNo == flightItem.flightNo">已选</div>
		<div class="flight-item-top flex ">
			<div class="flight-item-top-left">
					<div class="flight-aircom-info">
						<div class="flight-aircom-info-item">
							<img class="flight-icon-img" :src="flightItem.airlineImg" />
						</div>
						<div class="flight-aircom-info-item">
							<div class="flight-aircom-info-item-top">
								<span>{{flightItem.airlineName}}</span>
								
							</div>
							<div class="flight-aircom-info-item-bottom" v-on:mouseenter="onMouseEnterShowFlightAlert(flightItemCopy)" v-on:mouseleave="onMouseLeaveShowFlightAlert(flightItemCopy)">
								<span>{{flightItem.flightNo}}</span>
								&nbsp;
								<span>{{flightItem.planeName || flightItem.plane}}
								</span>
								&nbsp;
								<span v-show="flightItem.sharFlightNo">共享</span>
								<!-- 航班信息弹窗 -->
								<!--  -->
								<flight-info 
									v-if="flightItemCopy.isShowFlightAlert"
									class="flight-info-alert"
								   :flightItem="flightItem">
								</flight-info>
							</div>
						</div>
					</div>
					
				</div>
				<div class="flight-item-top-middle">
					<div class="flight-info-item">
						<div class="flex justify-content-space-between">
							<span class="flight-time">{{flightItem.departTime}}</span>
							<span class="flight-fly-time">{{flightItem.flyTimeStr}}</span>
							<span class="flight-time">{{flightItem.arriveTime}} <span class="day">{{calcDay(flightItem)}}</span></span>
						</div>
						<div class="flex justify-content-space-between">
							<div class="flight-airportName">{{flightItem.departAirportName}}{{flightItem.departTerminal}}</div>
							<span class="font12">{{flightItem.stopNum>0?'经停':''}}</span>
							<div class="flight-airportName">{{flightItem.arriveAirportName}}{{flightItem.arriveTerminal}}</div>
						</div>
						
					</div>
				</div>
				<div class="flight-item-top-right">
					<div class="flex justify-content-flex-end align-items-center">
						<div class="min-price-cabin-div"  :style="{'opacity':!flightItem.isFlightZhanKai?'1':'0'}">
							<div class="minprice-title"> 
								<span class="minprice-money">
									<span><dfn>￥</dfn>{{flightItem.minFilterPrice}}<span class="qi">起</span></span>
									
								</span>
							</div>
							<div >
								<span class="minprice-cabin">
									{{flightItem.minFilterPriceCabinTypeName}}
								</span>
								<span class="minprice-zhekou">
									
								</span>
							</div>
						</div>
						<div class="wei-bei" :title="flightItem.minPriceItem.violateItem" v-show="!flightItem.isFlightZhanKai && isWei(flightItem.minPriceItem)">
							违背
						</div>
						<div class="zhan-icon" @click="flightZhanKai(flightItem)">
							<span class="book-btn-shou" v-show="flightItem.isFlightZhanKai">收起 <i class="el-icon-arrow-up"></i></span>
							<span class="book-btn-zhan" v-show="!flightItem.isFlightZhanKai"
								:class="{'weiCanBook':getCanBookType(flightItem.minPriceItem) == 2,'qiang':getCanBookType(flightItem.minPriceItem) == 3,'weiNoCanBook':getCanBookType(flightItem.minPriceItem) == 4}"
							>订票 <i class="el-icon-arrow-down"></i></span>
							
							<div class="book-tooltip" v-show="!flightItem.isFlightZhanKai && flightItem.minPriceItem && flightItem.minPriceItem.seatCount && flightItem.minPriceItem.seatCount !='0' && flightItem.minPriceItem.seatCount!= 'A'">
								<span class="tooltip-tail"></span>
								<span class="tooltip-tail-bottom"></span>
								<div class="tooltip-content">剩{{flightItem.minPriceItem.seatCount}}张</div>
							</div>
						</div>
					</div>
					
				</div>
			
		</div>
		<!-- 价格列表 -->
		<!-- <transition name = "fade"> -->
			<div class="flight-price-list" :class="{'show':flightItem.isFlightZhanKai}" v-show="flightItem.isFlightZhanKai" >
				<!-- 循环舱位等级 -->
				<div  
					v-for="(cabinTypeItem,cabinTypeIndex) in flightItem.cabinTypeList" 
					:key="flightItem.flightNo +'_'+ cabinTypeItem.cabinType" 
					v-show="!cabinTypeItem['isNoShowCabinType'] && cabinTypeItem.isShow"
					
					>
					<!-- 循环同等舱位等级的 价格 -->
					<div class="flight-price-item"
						v-for="(priceItem,priceIndex) in cabinTypeItem.priceArr" 
						v-show="calcCabinPrice(cabinTypeIndex,cabinTypeItem,priceIndex,priceItem,tkQuery) " 
						:key="priceItem.priceId + (priceIndex.toString())">

						
						<div class="flight-price-item-bottom-line" v-show="!(priceIndex == 0 && cabinTypeIndex == 0 )" ></div>
						<!-- 价格左侧 -->
						<div class="flight-price-item-left">
							<!-- <div class="cabin-span" v-for="(priceItem,priceIndex) in cabinTypeItem.priceArr" :key="flightItem.flightNo +'_'+ cabinTypeItem.cabinType+'_'+ priceIndex">{{priceItem.cabin}}</div> -->
							<!-- <div class="guan-wang" v-show="priceItem.productType == 2">
								<img class="flight-price-item-icon-img" :src="flightItem.airlineImg" />
								<span>{{flightItem.airlineName}}官网</span>
							</div> -->
							
						</div>
						<!-- 价格右侧 -->
						<div class="flight-price-item-right">
							<!-- 舱位等级展示 -->
							<div class="flight-cabinType">
								<div @click="cabinTypeZhanKai(cabinTypeItem,cabinTypeIndex)" 
									v-show="priceIndex == 0 " >
									<span>{{cabinTypeItem.cabinTypeName}}</span>
									
									<span>
										&nbsp;
										<i class="el-icon-arrow-up" v-show="cabinTypeItem.isCabinTypeZhanKai"></i>
										<i class="el-icon-arrow-down" v-show="!cabinTypeItem.isCabinTypeZhanKai"></i>
									</span>
								</div>
							</div>
							<!-- 舱位价格单项展示  -->
							<div class="flight-cabin-price-item" >
								<div class="flight-price-item-right-price-item" >
									<span class="cabin-zhekou">
										<span class="cabin ">{{priceItem.cabin}}</span>
										<span>/</span>
										<span class="zhekou">{{priceItem.saleDiscount | filterDiscount}}</span>
									</span>
									
									<div class="exit-alter-rule"  
										v-on:mouseenter="onMouseEnterExpandPriceItemMap(flightItem,cabinTypeItem,cabinTypeIndex,priceItem,priceIndex)"
										v-on:mouseleave="onMouseLeaveExpandPriceItemMap(flightItem,cabinTypeItem,cabinTypeIndex,priceItem,priceIndex)"
									> 退/改签
											<product-rule
												v-if="(flightItem.flightNo + '_' + cabinTypeIndex + '_' + priceIndex) == cabinTypeIndexAndPriceIndex"
												class="exit-alter-rule-alert"
												style="position: absolute;top:30px ;left: -500px;z-index: 10;"
												:priceItem="priceItem"
											></product-rule>
											<span v-show="priceItem.baggage">&nbsp;行李额:{{priceItem.baggage | filterBaggage }}</span>
									</div>
									<div class="product-type-div">
										<div class="te-jia" v-show="priceItem.productType == 0">特价</div>
										<div class="common-price" v-show="priceItem.productType == 1">普通</div>
										<div class="guan-wang" v-show="priceItem.productType == 2">
											<img class="flight-price-item-icon-img" :src="flightItem.airlineImg" />
											<span class="flight-price-item-com-name">{{flightItem.airlineName}}官网</span>
										</div>
										<div class="xie-yi" v-show="priceItem.productType == 3">
											<span v-show="priceIndex>0">协议</span>
											<span v-show="priceIndex==0">协议最低价</span>
											<span class="jieSheng ">{{priceItem.priceId | filterXieYi(priceMap)}}</span>
										</div>
										<div class="zheng-cai" v-show="priceItem.productType == 4">政采</div>
										<!-- v-show="priceItem.productType == 5" 增值产品不受其他类型限制，可能同时出来 -->
										<el-tooltip placement="top" effect="light" v-show="priceItem.productName">
										  <div slot="content">{{priceItem.productDesc}}</div>
										  <div class="zeng-zhi-service"  >{{priceItem.productName}}</div>
										</el-tooltip>
										
										<!-- <span v-show="priceItem.isMinGuanWang">最低价官网</span>
										<span v-show="priceItem.isMinXieYi">最低价协议</span>
										<span v-show="priceItem.isMinCommon">最低价正常</span> -->
									</div>
									<div class="price-book-div">
										<span class="price" >
											<span style="position: relative;">
												<dfn>￥</dfn><span>{{priceItem.salePrice}}</span>
												<span v-show="tkQuery.serviceShowType==1&&priceItem.serviceFee>0" style="font-size: 12px;margin-left:2px ;">+{{priceItem.serviceFee}}</span>
											</span>
										</span>
										<!-- 其中 bookType   1  正常   2.违背,选择违背原因后预订  3.抢票  4.违背原因，不可预订  v-show="getCanBookType(priceItem) == 1 || getCanBookType(priceItem) == 2 || getCanBookType(priceItem) == 3" -->
										<div class="book-btn-div" :class="{'weiCanBook':getCanBookType(priceItem) == 2,'qiang':getCanBookType(priceItem) == 3,'weiNoCanBook':getCanBookType(priceItem) == 4}">
											<span class="wei-bei" :title="priceItem.violateItem" v-show="isWei(priceItem)">违背</span>
											<div class="book-btn" @click="bookCabin(flightItem,cabinTypeItem,priceItem)">
												
												<span>预订</span>
												<!-- <span>选为去程</span> -->
												<!-- <span>选为返程</span> -->
												<!-- 充足 -->
												<span v-show="priceItem.seatCount == 'A'"></span>
												<!-- 余票处理  priceItem.seatCount-->
												<div class="book-tooltip" v-show="priceItem.seatCount && priceItem.seatCount !='0' && priceItem.seatCount!= 'A'">
													<span class="tooltip-tail"></span>
													<span class="tooltip-tail-bottom"></span>
													<div class="tooltip-content">剩{{priceItem.seatCount}}张</div>
												</div>
												<!-- 已售罄 或者 已售罄可抢票 -->
												<div  class="book-tooltip" :class="{'no-seat-can-grab':priceItem.seatCount == '0'}"  v-show="priceItem.seatCount == '0'">
													<span class="tooltip-tail"></span>
													<span class="tooltip-tail-bottom"></span>
													<!-- <div class="tooltip-content">已售罄</div> -->
													<div class="tooltip-content">已售罄,可抢票</div>
												</div>
											</div>
										</div>
										
									<!-- 	<div class="book-btn-div" v-show="getCanBookType(priceItem) == 4">
											<span class="wei-bei" v-show="priceItem.violateItem">违背</span>
											<div class="book-btn" @click="bookCabin(flightItem,cabinTypeItem,priceItem)">
												预订
												
												
												<div class="book-tooltip" style="width: 100px;">
													<span class="tooltip-tail"></span>
													<span class="tooltip-tail-bottom"></span>
													<div class="tooltip-content">已违背,不可预订</div>
												</div>
												
											</div>
										</div> -->
										
										
										
										
									</div>
									
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		<!-- </transition> -->
	</div>
</template>

<script>
	import FlightInfo from './FlightInfo.vue'
	
	import ProductRule from './ProductRule.vue'
	
	export default{
		data(){
			return {
				flightItemCopy:{},

				cabinTypeIndexAndPriceIndex:'',
			}
		},
		components:{
			'product-rule':ProductRule,
			'flight-info':FlightInfo
		},
		props:{
			flightItem:{
				type:[Object],
				default(){
					return {}
				}
			},
			flightIndex:{
				type:[Number],
				default(){
					return -1
				}
			},
			priceMap:{
				type:[Object],
				default(){
					return {}
				}
			},
			chooseFlight:{
				type:[Object],
				default(){
					return {}
				}
			},
			tkQuery:{
				type:[Object],
				default(){
					return {}
				}
			}
			
		},
		mounted() {
			this.flightItemCopy = this.$common.deepCopy(this.flightItem) ;
		},
		methods:{
			// v-on:mouseenter="onMouseEnterShowFlightAlert" v-on:mouseleave="onMouseLeaveShowFlightAlert"
			onMouseEnterShowFlightAlert(flightItemCopy){
				flightItemCopy.isShowFlightAlert = true;
				this.$forceUpdate();
			},
			onMouseLeaveShowFlightAlert(flightItemCopy){
				flightItemCopy.isShowFlightAlert = false;
				this.$forceUpdate();
			},


			// expandPriceItemMap
			// v-on:mouseenter="onMouseEnterShowFlightAlert" v-on:mouseleave="onMouseLeaveShowFlightAlert"
			onMouseEnterExpandPriceItemMap(flightItem,cabinTypeItem,cabinTypeIndex,priceItem,priceIndex){

				console.log(flightItem,cabinTypeItem,cabinTypeIndex,priceItem,priceIndex)
				this.cabinTypeIndexAndPriceIndex  = (flightItem.flightNo + '_' + cabinTypeIndex + '_' + priceIndex);
	
				this.$forceUpdate();
			},
			onMouseLeaveExpandPriceItemMap(flightItem,cabinTypeItem,cabinTypeIndex,priceItem,priceIndex){
				this.cabinTypeIndexAndPriceIndex = '';
				this.$forceUpdate();
			},


			// 是否超标
			isWei(priceItem){
				let bool = false;
				// 如果返回违背事项，只要仅仅是[11106]【前后几小时】,就不违背
				if(priceItem['violateItem'] && priceItem['violateCode']!= '11106'){
					bool = true;
				}
				return bool

			},
			//1. 正常预订 不违背  2. 违背 预订提醒  3.抢票  4.不可预订
			getCanBookType(priceItem){
			  var canBookType = 1;
			  if(priceItem.canBook == '0'){
			    canBookType = 4;
				// 如果没有canBook字段，但是违背了，就选择违背原因
			  }else if((!priceItem.canBook || priceItem.canBook == '1') && priceItem['violateItem'] && !(priceItem.seatCount == '0' || !priceItem.seatCount)){
			    canBookType = 2;
			  }else if(priceItem.seatCount == '0' || !priceItem.seatCount){
			    canBookType = 3;
			  }else{
			    canBookType = 1;
			  }
			  return canBookType
			},
			// 计算天数
			calcDay(flightItem){
				var dayStr = '';
				let day = this.$common.getDiffDay(flightItem.departDate,flightItem.arriveDate);
				if(day>0){
					dayStr = `+${day}天`
				}
				return dayStr
			},
			
			
			//计算当前价格是否展示
			calcCabinPrice(cabinTypeIndex,cabinTypeItem,priceIndex,priceItem,tkQuery){
				
				
				let tkQueryGK =  tkQuery || {}; 
				var bool = true;
				// 当前价格是否展开
				let bool1 = true && (cabinTypeItem.isCabinTypeZhanKai || !cabinTypeItem.isCabinTypeZhanKai && priceIndex == 0);
				// 当前价格是官网价时，是不是最低价官网  isMinGuanWang
				let bool2 = true ;
				// if(priceItem.productType == 2 ){
				// 	if(!priceItem.isMinGuanWang){
				// 		bool2 = false;
				// 	}
				// }
				
				// 是否开启 统一舱位层级开启最低价预订  【仅因公】  ，转为后台控制
				let isOnlyCabinTypeMinPrice = (tkQueryGK.tripType == 1 && tkQueryGK.isOnlySameCabinMinPriceGk == 1);
				let bool3 = true;
				if(isOnlyCabinTypeMinPrice){
					
					// // 协议价最低价限制
					// if(priceItem.productType == 3 ){
					// 	bool3 = false;
					// 	if(priceItem.isMinXieYi){
					// 		bool3 = true;
					// 	}
					// }
					// 其他产品类型除  官网和协议
					// if(!(priceItem.productType == 3 || priceItem.productType == 2)){
						
					// if(!( priceItem.productType == 2)){
					// 	bool3 = false;
					// 	if(priceItem.isMinCommon){
					// 		bool3 = true;
					// 	}
					// }
					
				}
				// 当前价格，当企业开启只显示最低价参数时，只显示第一个价格， 需要考虑 登录人预订全免
				let bool10 = true;
				let isOnlyMinPriceGk =  tkQueryGK.appBook != 1 && tkQueryGK.tripType == 1 &&tkQueryGK.isOnlyMinPriceGk == 1;
				
				if(isOnlyMinPriceGk){
					if(cabinTypeIndex == 0 && priceIndex==0 ){
						bool10 = true
					}else{
						bool10 = false
					}
					
				}
				 
				
				if(bool1 && bool2 && bool3 && bool10){
					bool = true
				}else{
					bool = false
				}
				
				return bool
			},
			
			// 航班展开价格
			flightZhanKai(flightItem){
				let obj={
					flightItem:flightItem,
					flightIndex:this.flightIndex,
					bool:!flightItem.isFlightZhanKai,
					attrName:'isFlightZhanKai',
					
				}
				this.$emit('flightZhanKai',obj)
			},
			// 航班中某一个舱位等级是否展开多个舱位和价格
			cabinTypeZhanKai(cabinTypeItem,cabinTypeIndex){
				let obj = {
					flightItem:this.flightItem,
					flightIndex:this.flightIndex,
					cabinTypeIndex:cabinTypeIndex,
					bool:!cabinTypeItem.isCabinTypeZhanKai,
					attrName:'isCabinTypeZhanKai',
				}
				this.$emit('cabinTypeZhanKai',obj);
			},
			// 因私是否可以预订协议价
			isTripType1CanBookXieYi(airline){
				airline = airline || '';
				let bool = false;
				if(this.tkQuery.tripType1CanBookXieYi == 1){
				let tripType1CanBookAirlineCompany = this.tkQuery.tripType1CanBookAirlineCompany || '';
				  if(!tripType1CanBookAirlineCompany  || ( tripType1CanBookAirlineCompany.indexOf(airline))>-1 ){
				    bool = true;
				  }
				}
				return bool
				
			},
			// 选择某一个价格
			bookCabin(flightItem,cabinTypeItem,priceItem){
				// 因私模式，暂不允许预订协议价
				let airline = flightItem.airline || '';
				if(priceItem.productType == 3 && this.tkQuery && this.tkQuery.tripType == 2 && !this.isTripType1CanBookXieYi(airline) ){
					let tips = '因私模式下，不允许线上预订三方协议产品，请联系客服处理！'
					this.$confirm(tips, '温馨提示', {
					  confirmButtonText: '选择其他舱位',
					  cancelButtonText: '取消',
					  type: 'warning',
					  // center: true,
					  closeOnPressEscape:false,
					  closeOnClickModal:false,
					}).then(() => {
					
					}).catch(() => {
												
					});
					return
				}
				let obj = this.$common.deepCopy(flightItem)
				obj.chooseCabin =priceItem;
				// 获取当前的预订类型
				obj.chooseCabin.bookType = this.getCanBookType(priceItem);
				this.$emit('chooseFlight',obj)
			},
			
			// 修改显示退改签产品规则
			changeShowProductRule(priceItem,atteName,bool){
				priceItem[atteName] = bool;
			},
		},
		filters:{
			filterCabinTypeName(val){
				let cabinMap = {
					'Y':'经济舱',
					'ES':'超值经济舱',
					'C':'公务舱',
					'F':'头等舱'
				}
				return cabinMap[val]
				
			},
			// 处理协议价 的价格差价
			filterXieYi(priceId, priceMap) {
				if(priceMap && priceId && priceMap[priceId]) {
					var nowPrice = priceMap[priceId]['salePrice'] || 0; // 协议价
					var puPrice = priceMap[priceId]['norProductPrice'] || 0;
					var priceDiff = puPrice - nowPrice;
					if(priceDiff > 0) {
						return '｜节省￥' + priceDiff ;
					}
				}
			},
			
		},
	}
</script>

<style scoped="scoped" lang="scss">
	.choosed-flight-item{
		// background-image: linear-gradient(170deg,#26c28d,#00a876);
		background-image: linear-gradient(170deg,  #eef1f6,#97dbc4);
	}
	.choose-tips-btn{
		position: absolute;
		top: 0;
		left:0;
		background-color: rgba(38,194,141,.1);
		color: #00a876;
		padding: 0 10px 0 10px;
		font-size: 12px;
		border-radius: 0 0 6px 0;
		
	}
	.flight-item{
		position:relative;
		font-family: '微软雅黑';
		.flight-item-top:not(.active):hover {
			box-shadow: 0 4px 15px rgba(0, 0, 0, .18);
			z-index: 3
		}
		
		.flight-item-top.active {
			border-top: 1px solid #dadada;
			z-index: 2
		}
		.flight-item-top{
			border-top: 1px solid transparent;
			box-shadow: 0 1px 1px hsla(0, 0%, 80%, .5);
			-webkit-transition: -webkit-box-shadow .15s;
			-webkit-transition: box-shadow .15s;
			transition: box-shadow .15s;
			
			// box-sizing: content-box;
			padding: 15px 0;
			display: flex;
			justify-content: space-between;
			
			// 航班信息左侧
			.flight-item-top-left{
				width: 258px;
			
				.flight-aircom-info{
					padding-left: 20px;
					text-align: left;
					display: flex;
					align-items: center;
				}
				/* 航司信息展示 */
				.flight-aircom-info-item{
					display: inline-block;
				}
				
				.flight-aircom-info-item-top{
					padding-top: 4px;
					font-size: 13px;
					font-weight: 600;
					color: #010101;
				}
				.flight-aircom-info-item-bottom{
					padding-top: 4px;
					font-size: 11px;
					font-weight: 400;
					color: #1C83ED;
					cursor: pointer;
					position: relative;
					.flight-info-alert{
						position: absolute;
						top:20px ;
						left: -80px;
						// display: none;
						z-index: 10;
					}
				}
				// .flight-aircom-info-item-bottom:hover{
					
				// 	.flight-info-alert{
				// 		display: block;
				// 		z-index: 10;
				// 	}
				// }
				.flight-icon-img{
					width: 40px;
					height: 35px;
					display: inline-block;
					margin-right: 15px;
				}
			}
			// 航班信息中间
			.flight-item-top-middle{
				width: 400px;
				/* 航班信息展示 */
				.flight-time{
					font-size: 22px;
					font-weight: 600;
					color: #424242;
					position: relative;
					.day{
						position: absolute;
						font-size: 12px;
						color: #398efe;
						right: -30px;
						top: -2px;
						white-space: nowrap;
						cursor: help;
					}
				}
				.flight-fly-time{
					font-size: 12px;
					display: inline-block;
					width: 180px;
					height: 20px;
					text-align: center;
					border-bottom: 2px solid #E6E6E6 ;
				}
				.flight-airportName{
					font-size: 14px;
					font-weight: 400;
					color: #808080;
					
				}
				.flight-info-item{
					// border-left:1px solid #EBEBEB;
					// border-right:1px solid #EBEBEB;
					padding: 0 20px;
				}
			}
			// 航班信息右侧
			.flight-item-top-right{
				width: 481px;
				padding-left: 30px;
				padding-right: 20px;
				.min-price-cabin-div{
					text-align: right;
					margin-right: 15px;
				}
				.minprice-title{
					color: #808080;
					font-size: 14px;
				}
				.minprice-cabin{
					// color: #424242;
					color: #919ca2;
					font-size: 12px;
				}
				.minprice-money{
					// color:#FF6402;
					color: #1C83ED;
					font-size: 26px;
					font-weight: bold;
					dfn{
						font-size: 18px;
					}
					.qi{
						font-size: 12px;
					}
				}
				.wei-bei{
					display: inline-block;
					width: 44px;
					height: 31px;
					line-height: 30px;
					background-color:#FFF6ED ;
					font-size: 13px;
					font-weight: 600;
					color: #FF6302;
				}
				.zhan-icon{
					color:#424242;
					position: relative;
					cursor: pointer;
					
					
					.iconfont{
						font-size: 16px;
					}
					.book-tooltip{
						
					}
				}
				.book-btn-zhan{
					display: inline-block;
					width: 57px;
					height: 31px;
					// background: #FF9524;
					background: linear-gradient(90deg, #FF8004 0%, #FF4800 100%);
					text-align: center;
					line-height: 30px;
					font-weight: bold;
					color: #FFFFFF;
					font-size: 13px;
					
				}
				// 违背，可预订
				.book-btn-zhan.weiCanBook{
					background: orangered;
				}
				// 抢票
				.book-btn-zhan.qiang{
					background: lightblue;
				}
				// 违背，不可预订
				.book-btn-zhan.weiNoCanBook{
					background: lightgray;
				}
				.book-btn-shou{
					display: inline-block;
					width: 57px;
					height: 31px;
					color: #FF6302 !important;
					border: 1px solid #FF6302;
					text-align: center;
					line-height: 30px;
					font-weight: bold;
					background-color: white;
					font-size: 13px;
				}
				
			}
		}
		
		// 舱位、价格信息展示
		.flight-price-list.show{
			
			height: auto;
		}
		.flight-price-list{
			background-color: #f8fafb;
			-webkit-transition: all .5s linear;
			transition: all .5s linear;
			height:0;
			.flight-price-item:hover{
				background-color: #ebf6ff;
			}
			.flight-price-item{
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 15px 0;
				-webkit-transition: all .5s linear;
				transition: all .5s linear;
				.flight-price-item-bottom-line{
					// width: "";
					height: 1px;
					background: #e4e4e4;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 1160px;
					margin: 0 20px;
				}
				.flight-price-item-left{
					width: 366px;
					// display: flex;
					padding-left: 20px;
					text-align: left;
					
					
					.cabin-span{
						display: inline-block;
						width: 20px;
						height: 20px;
						background: #F0F0F0;
						border-radius: 10px;
						margin-right: 15px;
						margin-bottom: 15px;
						text-align: center;
						line-height: 20px;
						color: #808080;
						font-size: 12px;
					}
				}
				.guan-wang{
					height: 21px;
					display: inline-flex;
					// justify-content: ;
					align-items: center;
					
					// border: 1px solid #1C83ED;
					border: 1px solid #e4e4e4;
					// margin-left: 55px;
					padding: 0 10px;
					border-radius: 2px;
					.flight-price-item-icon-img{
						width: 14px;
						height: 14px;
					}
					.flight-price-item-com-name{
						font-size: 12px;
						font-weight: bold;
						// color: #000000;
						color: #1C83ED;
					}
					img{
						height: 12px;
						width: 14px;
					}
				}
				.product-type-div{
					width: 150px;
					.xie-yi{
						    color: #00b87a;
						    border: 1px solid #bfeddd;
						    display: inline-block;
						    border-radius: 2px;
						    padding: 1px 4px 2px;
						    line-height: 12px;
						    font-size: 12px;
						    vertical-align: middle;
					}
					.common-price{
						color: #808080;
						opacity: 0;
					}
					.te-jia{
						    background: -webkit-gradient(linear,right top,left top,from(rgba(235,246,255,.3)),to(#e1f1fe));
						    background: -webkit-linear-gradient(right,rgba(235,246,255,.3),#e1f1fe);
						    background: linear-gradient(-90deg,rgba(235,246,255,.3),#e1f1fe);
						    color: #0086f6;
						    display: inline-block;
						    padding: 1px 4px;
						    line-height: 16px;
						    font-size: 12px;
						    font-weight: 500;
						    vertical-align: middle;
					}
					.zeng-zhi-service{
				
						background: linear-gradient(90deg, #FF8004 0%, #FF4800 100%);
						
						    color: #FFFFFF;
						// border: 1px solid red;
						display: inline-block;
						padding: 1px 4px;
						line-height: 16px;
						font-size: 12px;
						font-weight: 500;
						vertical-align: middle;
					}
				}
				
				.flight-price-item-right{
					width: 774px;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					justify-content: flex-end;
					.flight-cabinType{
						height: 30px;
						width: 131px;
						font-size: 14px;
						display: flex;
						align-items: center;
						font-weight: 400;
						color: #262633;
						// justify-content: flex-end;
						// padding-right: 30px;
						cursor: pointer;
						
						
					}
					.flight-cabin-price-item{
						width: 643px;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						.flight-price-item-right-price-item{
							width: 100%;
							display: flex;
							justify-content:space-between;
							
							align-items: center;
							font-size: 14px;
							color: #424242 ;
							
							.cabin-zhekou{
								width: 61px;
								text-align: left;
								.cabin{
									color: #8C8CA0;
								}
							}
							.exit-alter-rule{
								font-size: 12px;
								width: 160px;
								// text-align: left;
								text-align: center;
								font-weight: 400;
								color: #1C83ED;
								position: relative;
								// .exit-alter-rule-alert{
								// 	display: none;
								// 	color: #424242;
								// }
							}
							// .exit-alter-rule:hover{
							// 	.exit-alter-rule-alert{
							// 		display: block;
							// 	}
								
							// }
							.price-book-div{
								width:281px;
								padding-right: 20px;
								display: flex;
								justify-content:flex-end;
								align-items: center;
								.price{
									width: 80px;
									font-size: 21px;
									font-weight: bold;
									color: #1C83ED;
									text-align: right;
									dfn{
										font-size: 13px;
									}
								}
								// 违背，可预订
								.book-btn-div.weiCanBook{
									.book-btn{
										background: orangered;
									}
								}
								// 抢票
								.book-btn-div.qiang{
									.book-btn{
										background: lightblue;
									}
								}
								// 违背，不可预订
								.book-btn-div.weiNoCanBook{
									.book-btn{
										background: lightgray;
									}
								}
								.book-btn-div{
									width: 160px;
									display: flex;
									justify-content: flex-end;
									align-items: center;
									cursor: pointer;
									.wei-bei{
										display: inline-block;
										width: 44px;
										height: 31px;
										line-height: 30px;
										background-color:#FFF6ED ;
										font-size: 13px;
										font-weight: 600;
										color: #FF6302;
									}
									.book-btn{
										width: 57px;
										height: 31px;
										background: linear-gradient(90deg, #FF8004 0%, #FF4800 100%);
										text-align: center;
										line-height: 30px;
										font-weight: bold;
										color: #FFFFFF;
										// margin-left:15px;
										position: relative;
										
									}
								}
								
								
								
								
							}
							
							
							
						}
					}
					
				}
			}
		}
		
	}
	
	// 提示框
	.book-tooltip{
		visibility: visible;
		display: inline-block;
		z-index: 10;
		position: absolute;
		// right: 50%;
		right: 18px;
		top: -18px;
		padding: 2px;
		width: 60px;
		min-width: 42px;
		line-height: 12px;
		background-color: #FF9702;
		color: #fff;
		font-size: 12px;
		text-align: center;
		border-radius: 2px 2px 0 2px;
		-webkit-text-size-adjust: none;
		-webkit-transform: scale(.83) translateX(50%);
		transform: scale(.83) translateX(50%);
		span{
			overflow: hidden;
			position: absolute;
			width: 0;
			height: 0;
			font-size: 0;
		}
		.tooltip-tail {
		    z-index: -2;
		    bottom: -6px;
		    left: 30px;
		    border: 6px solid transparent;
		    border-right-color: #FF9702;
			
		}
		.tooltip-tail-bottom {
		    z-index: -4;
		    bottom: -9px;
		    left: 29px;
		    border: 7px solid transparent;
		    border-right-color: #fff;
		}
	}
	.book-tooltip.no-seat-can-grab{
		background-color: #1C83ED;
		.tooltip-tail{
			border-right-color: #1C83ED;
		}
	}

	
	
	
</style>
